import { useRef, useState } from "react";

const useCarouselVideoConfig = (sliderRef, widgetOptions) => {
  const videoTimeoutRef = useRef([]);
  const [videoConfig, setVideoConfig] = useState({
    isVideo: false,
    duration: 0
  })

  function resumeSlider() {
    setTimeout(() => {
      sliderRef.current.slickPlay();
    }, 100);
  }

  function removeTimeoutAndConfig() {
    setVideoConfig({
      duration: widgetOptions?.autoplaySpeed,
      isVideo: false
    })
    if (videoTimeoutRef.current?.length) {
      clearTimeout(videoTimeoutRef.current[0]);
      resumeSlider();
    }
  }

  const handleVideoDuration = (val, isVideoVisible) => {
    if (val && widgetOptions?.autoPlay && isVideoVisible) {
      sliderRef.current.slickPause();
      setVideoConfig({
        duration: val,
        isVideo: true
      })
      const timeoutId = setTimeout(() => {
        sliderRef.current.slickNext();
        removeTimeoutAndConfig()
        resumeSlider()
      }, val * 1000)
      videoTimeoutRef.current.push(timeoutId)
    }
    else if (!isVideoVisible && videoTimeoutRef.current?.length > 1) {
      clearTimeout(videoTimeoutRef.current[0])
      videoTimeoutRef.current = videoTimeoutRef.current?.slice(1)
    }
  }

  return { videoConfig, handleVideoDuration }

}

export default useCarouselVideoConfig;